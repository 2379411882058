*
{
    margin: 0;
    padding: 0;
    
}

:root{
    --color01: #DDDDDD;
    --color02: #007880;
    --color03: #002d81;
    --color04: #022C43;

    --color01_t: #ddddddce;
    --color02_t: #007780be;
    --color03_t: #6b778dbd;
    --color04_t: #022c43c4;
}

html,
body
{
    overflow: hidden;
}

.webgl
{
    position: fixed;
    top: 0;
    left: 0;
    outline: none;
    z-index: -1;
}

.navbar {
	z-index: 2;
    background-color: var(--color02) !important;
    position: absolute;
    left: 0;
    right: 0;
}

.navbar-brand{
    color: var(--color01) !important;
    font-size: large;
    font-family: KingRichard,'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.nav-item{
    color: var(--color01) !important;
    font-family: KingRichard, 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.active{
    color: var(--color04) !important;
    font-family: KingRichard,'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-weight: bolder !important;
}
.projectBnt{
    position: absolute;
    z-index: 3;
    width: 300px;
    left: 0px;
    right: 0;
    margin: auto;
    top: 75%;
    z-index: 5;
    background-color: var(--color02_t);
    font-family: KingRichard,'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif !important;
}
.nav-item:hover{
    color: var(--color04)  !important;
}

.projectBnt:hover{
    color: var(--color04)  !important;
    background-color: var(--color02) !important;
}


.card{
    max-height:250px;
    overflow:auto;
    border: none;
    background-color: rgba(0, 255, 179, 0);
    z-index: 50 !important;
    
}

.h1{
    color: var(--color01) !important;
    font-size: min(max(40px,10vw), 150px);
    line-height: 50%;
    font-family: KingRichard,'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    -webkit-text-stroke: 2px var(--color02);
    text-shadow:
       3px 3px 0 #000,
     -1px -1px 0 #000,  
      1px -1px 0 #000,
      -1px 1px 0 #000,
       1px 1px 0 #000;
}

.list-group-item{
    font-family: KingRichard,'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: large;
    color: var(--color01);
    background-color: var(--color02_t) !important;
    
}

@font-face {
    font-family: KingRichard;
    src: url('./fonts/king-richard.regular.ttf') format('truetype');
}

.row{
   align-items: center;
}

.rowDesc{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
    align-items: top;
}

.descCont{
    position: absolute;
    z-index: 3;
    text-align: justify;
    text-justify: inter-word;
    overflow: auto;
    justify-content: center;
    left: 0px;
    right: 0;
    top: 75%;
    margin-left: auto; 
    margin-right: auto; 
    background-color: var(--color02_t);
    color: var(--color01);
    font-size: min(20px, 3vw);
    font-family: KingRichard,'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    text-shadow:
       3px 3px 0 #000,
     -1px -1px 0 #000,  
      1px -1px 0 #000,
      -1px 1px 0 #000,
       1px 1px 0 #000;
}

.aboutMe{
    position: absolute;
    height: 85vh;
    width: 95%;
    z-index: 0;
    overflow: auto;
    left: 0px;
    right: 0;
    top: 75px;
    margin-left: auto; 
    margin-right: auto; 
    margin-top: auto; 
    margin-bottom: auto; 
    background-color: var(--color02_t);
    color: var(--color01);
    font-size: min(25px, 4vw);
    font-family: KingRichard,'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    text-shadow:
       3px 3px 0 #000,
     -1px -1px 0 #000,  
      1px -1px 0 #000,
      -1px 1px 0 #000,
       1px 1px 0 #000;
}

.titleCont{
    position: absolute;
    pointer-events: none;
    z-index: 0;
    left: 0;
    right: 0;
    width: 100%;
    margin-left: auto; 
    margin-right: auto; 
    justify-content: center;
    top: 55px;
}

p{
    margin: 0%;
}

.img-responsive{
    width: 50px;
}

.modal-content{
    background-color: var(--color02_t);
    color: var(--color01);
    font-family: KingRichard,'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif !important;
}

h4 a{
    color: var(--color02);
    text-shadow:
       3px 3px 0 #000,
     -1px -1px 0 #000,  
      1px -1px 0 #000,
      -1px 1px 0 #000,
       1px 1px 0 #000;
}

#loading-screen {
	position: absolute;
	z-index: 50;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: #000000;
	opacity: 1;
 	transition: 1s opacity;
}

#loading-screen.fade-out {
    opacity: 0;
}

#loader {
    display: block;
    position: relative;
    left: 50%;
    top: 50%;
    width: 150px;
    height: 150px;
    margin: -75px 0 0 -75px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: var(--color01);
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
}
#loader:before {
    content: "";
    position: absolute;
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: var(--color02);
    -webkit-animation: spin 3s linear infinite;
    animation: spin 3s linear infinite;
}
#loader:after {
    content: "";
    position: absolute;
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: var(--color03);
    -webkit-animation: spin 1.5s linear infinite;
    animation: spin 1.5s linear infinite;
}
@-webkit-keyframes spin {
    0%   {
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes spin {
    0%   {
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

::-webkit-scrollbar-track
{
	border: 1px solid var(--color04);
	background-color: var(--color04);
}

::-webkit-scrollbar
{
	width: 10px;
	background-color: var(--color03);
}

::-webkit-scrollbar-thumb
{
	background-color: var(--color02);	
}